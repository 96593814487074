import { Config } from "@configured/EnvironmentConfig";
import {
  Chunk,
  FirmwareInfo,
  FirmwareVersion,
  TransactFn,
  dfuSideload,
} from "./ported/dfu";

const minVersionForODFU: FirmwareVersion = {
  ver_major: 5,
  ver_minor: 3,
  ver_patch: 1,
};

const minVersionForODFULora: FirmwareVersion = {
  ver_major: 7,
  ver_minor: 5,
  ver_patch: 2,
};

type HubUploadQueryResponse = { err?: string; uploads?: FirmwareInfo[] };

type HubUploadGetRequest = {
  req: "hub.upload.get";
  type: "notecard";
  compress: string;
  name: string; // e.g. "notecard-4.1.1.4015681$20221206134917.bin";
  offset: number;
  length: number;
};
type HubUploadGetResponse = Chunk | { err: string };

const notehubApiUrl = Config.notehubReqApiUrl;

// When we started shipping notecards with different MCUs, the `target` field
// was added to the firmware metadata (and card.version response). This is the
// target for the vintage notecards before the target field was added.
const vintageTarget = "r5";

async function fetchAllFirmware(
  allowUnpublished = false,
): Promise<FirmwareInfo[]> {
  // $ curl -L 'https://api.notefile.net/req' -d '{"req":"hub.upload.query","type":"notecard"}'
  const req = JSON.stringify({
    req: "hub.upload.query",
    type: "notecard",
    allow: allowUnpublished,
  });

  const response = await fetch(notehubApiUrl, { method: "POST", body: req });
  const object = (await response.json()) as HubUploadQueryResponse;
  if (object.err || !object.uploads) {
    throw new Error(`Could not get firmware list from notehub ${object.err}`);
  }
  return object.uploads;
}

// memoize fetchAllFirmware
const firmwareMemo = {
  published: [] as FirmwareInfo[],
  all: [] as FirmwareInfo[],
};
// getFirmware is a memoized fetchAllFirmware
export const getFirmware: typeof fetchAllFirmware = async (
  allowUnpublished = false,
) => {
  const key = allowUnpublished ? "all" : "published";
  if (!firmwareMemo[key].length) {
    firmwareMemo[key] = await fetchAllFirmware(allowUnpublished);
  }
  return firmwareMemo[key];
};

interface CardVersionResponse {
  body?: {
    product?: string;
    target?: string;
  };
}

// compare firmware based on major minor patch and build
export function compareVersions(aVer: FirmwareVersion, bVer: FirmwareVersion) {
  const comps = [
    [aVer.ver_major, bVer.ver_major],
    [aVer.ver_minor, bVer.ver_minor],
    [aVer.ver_patch, bVer.ver_patch],
    [aVer.ver_build, bVer.ver_build],
  ];
  for (let i = 0; i < comps.length; i += 1) {
    const [a, b] = comps[i];
    if (a === undefined || b === undefined) {
      break;
    }
    if (a !== b) {
      return a - b;
    }
  }
  return 0;
}

// Essentially check for non-buggy ODFU implementations.
export function actuallySupportsODFU(old_version: FirmwareVersion): boolean {
  if (old_version.target === "wl")
    return compareVersions(old_version, minVersionForODFULora) >= 0;
  return compareVersions(old_version, minVersionForODFU) >= 0;
}

// Ignoring sideload-capabilities, check if the card and firmware are
// theoretically compatible. If actuallySupportsODFU() is false, then the card
// is not compatible with ODFU and STM32 Cube Programmer should be used for DFU.
export function isTheoreticallyCompatible(
  card: CardVersionResponse,
  fwInfo: FirmwareInfo,
) {
  const dev = card.body;
  if (!dev) return false;
  if (!fwInfo.firmware) return false;
  const { firmware } = fwInfo;
  const target = dev.target || vintageTarget;
  if (dev.product !== firmware?.product) return false;
  if (target !== fwInfo.firmware?.target) return false;
  return true;
}

export async function listCompatibleFirmware(
  allowUnpublished: boolean,
  deviceTransact: TransactFn,
  allFirmware?: FirmwareInfo[],
) {
  const card = (await deviceTransact([
    `{"req":"card.version"}`,
  ])) as CardVersionResponse;

  const fwList = allFirmware || (await getFirmware(allowUnpublished));
  const compatibleFirmware = fwList.filter((fw) =>
    isTheoreticallyCompatible(card, fw),
  );
  return compatibleFirmware;
}

export async function getFirmwareChunk(
  name: string,
  offset: number,
  byteLength: number,
  compressionMode: string,
): Promise<Chunk> {
  const req: HubUploadGetRequest = {
    req: "hub.upload.get",
    type: "notecard",
    name,
    compress: compressionMode,
    offset,
    length: byteLength,
  };

  const response = await fetch(notehubApiUrl, {
    method: "POST",
    body: JSON.stringify(req),
  });
  const object = (await response.json()) as HubUploadGetResponse;
  if ("err" in object) {
    throw new Error(`Could not get firmware chunk from notehub ${object.err}`);
  }
  return object;
}

export async function doDFU(
  fw: FirmwareInfo,
  deviceTransact: TransactFn,
  reportStatus: (english: string, fraction: number) => void,
) {
  const result = await dfuSideload(
    fw,
    getFirmwareChunk,
    deviceTransact,
    reportStatus,
  );
  return result;
}
